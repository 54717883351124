import { storeToRefs } from 'pinia';

export default defineNuxtRouteMiddleware((to) => {
	const authStore = useAuth();
  const { authenticated } = storeToRefs(authStore);
  const token = useCookie('token');

  if (token.value) {
		console.log(' ');
		console.log('--- AUTHORIZED: ', to.href)
    // check if value exists
		if (authenticated.value != true) {
			authenticated.value = true;
		}
  }

  // if token doesn't exist redirect to log in
  if (!token.value) {
		console.log(' ');
		console.log('--- NOT AUTHORIZED: ', to.href)

    abortNavigation();

    return navigateTo('/');
  }
});